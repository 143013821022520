import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  img {
    transform: translate(10px, 5px);
  }
`;

const Footer = () => (
  <StyledFooter>
    <p className="center">
      &copy; Slicks Slices {new Date().getFullYear()}
      <img
        src="https://api.netlify.com/api/v1/badges/3e790434-b095-4dd1-91e0-0d029c5ea26d/deploy-status"
        alt="Netlify Build Status"
      />
    </p>
  </StyledFooter>
);

export default Footer;
