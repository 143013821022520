import React, { useState } from 'react';

// Create the order context
const OrderContext = React.createContext();
export default OrderContext;

const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState([]);
  return (
    <OrderContext.Provider value={[order, setOrder]}>
      {children}
    </OrderContext.Provider>
  );
};
export { OrderProvider };
